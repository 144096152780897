import React from "react"
import { Link,useStaticQuery, graphql } from "gatsby"
//import HtmlParser from '../helpers/htmlParser';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function BlogPromo () {
    const data = useStaticQuery(graphql`
    query BlogPromoQuery {
        allNodePageBlog(limit: 3, sort: {fields: created, order: DESC}) {
            nodes {
                id
                created
                title
                body {
                    processed
                }
                path {
                    alias
                }
            }
        }
    }
  `);

    const blogs = data.allNodePageBlog.nodes.map((item, index) => {

        var rawdate = item.created;
        var na = rawdate.toString();
        var date1 = na.slice(0, 10);
        var date2 = date1.split(' ')[0].split('-');
        var dateFull = date2[1] + '/' + date2[2] + '/' + date2[0];

        const processedText = item?.body?.processed.replace(/(<([^>]+)>)/gi, "")

        let summary = <HTMLEllipsis
                        unsafeHTML={processedText.slice(0,89)}
                        maxLine='3'
                        ellipsis='...'
                        basedOn='letters'
                        />
                        
        return (
        <div className='press-promo' key={item.title}>
           <div className="blog-promo-icon blog-promo-icon"><FontAwesomeIcon icon='comments' /></div>
           <div className="press-promo-content">
               <div className='press-promo-title press-promo-title'>
                   <Link to={item.path.alias}>{item.title}</Link>
                   <div className="press-promo-date"><FontAwesomeIcon icon='calendar' /> {dateFull}</div>
               </div>
           </div>
        </div>  
        )
    } )

    return (
        <>
            {blogs}        
        </>
    )
}

